if (typeof tarteaucitron !== 'undefined') {
    var tarteaucitronForceLanguage = document.getElementsByTagName('html')[0].getAttribute('lang').split('-')[0];

    tarteaucitron.init({
        hashtag: "#tarteaucitron", /* Ouverture automatique du panel avec le hashtag */
        highPrivacy: false, /* désactiver le consentement implicite (en naviguant) ? */
        orientation: "bottom", /* le bandeau doit être en haut (top) ou en bas (bottom) ? */
        adblocker: false, /* Afficher un message si un adblocker est détecté */
        showAlertSmall: true, /* afficher le petit bandeau en bas à droite ? */
        cookieslist: true, /* Afficher la liste des cookies installés ? */
        removeCredit: false /* supprimer le lien vers la source ? */
    });

    // Google Analytics
    tarteaucitron.user.gajsUa = document.getElementsByTagName('body')[0].getAttribute('data-gaid');
    (tarteaucitron.job = tarteaucitron.job || []).push('gajs');
}
